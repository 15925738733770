import styled from 'styled-components';

export const PhoneLayoutStyle = styled.div`
  height: 100vh;
  overflow: hidden;
  canvas {
    height: 100vh;
    width: 100vw;
    &:hover {
      cursor: grab;
    }
    &:focus {
      outline: none;
    }
  }
  video {
    display: none;
  }
  .right-banner {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 260px;
    font-weight: 600;
    transform-origin: 50% 50%;
    transform: rotate(0deg);
    user-select: none;
    overflow: hidden;
  }

  .navigation {
    position: fixed;
    top: 48px;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 2;
    padding: 0 100px ;
    flex-wrap: wrap;

    .navigation-item {
      margin-right: 12px;
      padding: 4px;
      cursor: pointer;
      position: relative;
      color: #777777;
      transition: 0.3s ease-in-out all;
      font-size: 16px;
      &:before {
        content: "";
        width: 0%;
        height: 2px;
        transition: 0.3s ease-in-out all;
        background: black;
        position: absolute;
        bottom: 0px;
        left: 0px;
        z-index: -1;
      }
      &.active {
        color: white;
        &:before {
          content: "";
          height: 100%;
          width: 100%;
        }
      }
      &:hover {
        color: black;
        &:before {
          content: "";
          width: 100%;
        }
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  .artwork-navigation-left {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
    padding: 20px;
    cursor: pointer;
    font-size: 72px;
    font-weight: 600;
    z-index: 2;
  }
  .artwork-navigation-right {
    position: absolute;
    top: 50%;
    padding: 20px;
    cursor: pointer;
    font-size: 72px;
    font-weight: 600;
    right: 40px;
    transform: translateY(-50%);
    z-index: 2;
  }

  .artwork-info {
    position: fixed;
    bottom: 40px;
    left: 0;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;

    .artwork-info-modal {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
    }

    &__name {
      margin-bottom: 4px;
      font-size: 24px;
    }
    &__description {
      color: #444444;
      transition: 0.3s ease-in-out all;
      font-size: 18px;
      &:hover {
        color: black;
      }
    }


    .artwork-info-slides {
      display: flex;
      margin-bottom: 12px;

      .artwork-info-slides-item {
        width: 8px;
        height: 8px;
        border: 1px solid black;
        margin-right: 8px;
        transform: skew(-35deg, 0);
        cursor: pointer;
        transition: 0.3s ease-in-out all;
        background: white;
        &:last-child {
          margin-right: 0px;
        }
        &.active {
          background: black;
        }
      }
    }
  }

  @media (max-width: 1300px) {
    .right-banner {
    font-size: 200px;
  }

  
  .navigation {
    position: fixed;
    top: 48px;
    left: unset;
    right: 60px;
    display: flex;
    justify-content: flex-end;
    width: unset
    z-index: 2;
    padding: 0px;
    flex-wrap: wrap;
  }

  @media (max-width: 1100px) { 
    .navigation {
    .navigation-item {
      margin-right: 12px;
      font-size: 16px;
      }
      }
      .right-banner {
    font-size: 160px;
  }
  }
  @media (max-width: 920px) { 
    .navigation {
      flex-direction: column;
      width: auto;
      left: unset;
      right: 42px;
    .navigation-item {
      margin-right: 12px;
      font-size: 16px;
      margin-bottom: 12px;
      }
      }
      .right-banner {
    font-size: 120px;
  }
  }
  @media (max-width: 520px) { 
    margin-top: 20px;
    .navigation  {
      flex-direction: row;
      justify-content: center;
      top: 80px;
      margin: 0 auto;
      padding:0 20px;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
      .navigation-item { 
        font-size: 12px;
      }
    }
    .right-banner { 
      display: none;
    }
    .artwork-info {
      bottom: 20px;
    }
    .artwork-navigation-left { 
      left: 20px;
      font-size: 32px;
    }
    .artwork-navigation-right { 
      right: 20px;
      font-size: 32px;
    }
  }
`;
