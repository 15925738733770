import styled from 'styled-components';

export const ModalArtistStyle = styled.div`
  z-index: 100;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  background: rgba(255, 255, 255, 0.4);
  transition: 0.3s ease-in-out all;
  &.active {
    opacity: 1;
    visibility: visible;
  }

  .modal-artist-container {
    background: white;
    padding: 48px;
    padding-right: 52px;
    position: relative;
    max-width: 620px;
    max-height: 60vh;
    overflow: auto;
    &__close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 32px;
      height: 32px;
      padding: 4px;
      cursor: pointer;
    }
  }

  .modal-artist-container-content {
    &__section {
      margin-bottom: 42px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    &__title {
      font-size: 24px;
      font-size: 34px;
      margin-bottom: 12px;
      span {
        font-size: 12px;
      color: #888888;
      }
    }
    &__content {
      font-size: 16px;
      line-height: 24px;
      color: #666666;
      p {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    &__contact__email {
      text-decoration: underline;
      font-size: 12px;
      margin-top: 12px;
    }
  }
`;
