import React, { Suspense, useRef, useEffect, useState } from 'react';
import { Box, OrbitControls, ambientLight, Sky } from 'drei';
import { Canvas, useThree, useLoader, useFrame } from 'react-three-fiber';
import * as THREE from 'three';
import { PhoneLayoutStyle } from './phone-layout-style';
import PhoneModel from './phone-text';
import IPhoneModel from './Iphone';
import Header from '../../components/header/header';
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader';
import ModalArtist from './modal-artist/modal-artist';
import { artistDescription } from './artist-description';
import gsap from 'gsap';
import ModalExpo from './modal-artist/modal-expo';

const LoaderBox = () => {
  const loaderRef = useRef();
  const loaderMaterial = new THREE.MeshStandardMaterial({
    color: 0x666666,
    wireframe: true,
  });

  useFrame(() => {
    loaderRef.current.rotation.x += 0.01;
    loaderRef.current.rotation.y += 0.01;
  });

  return <Box ref={loaderRef} material={loaderMaterial} scale={[0.4, 0.4, 0.4]} />;
};

export const MainDirectionalLight = () => {
  const mainDirectionalLight = useRef();
  const mainDirectionalLightTarget = useRef();

  // useHelper(mainDirectionalLight, DirectionalLightHelper, 0.5, 'hotpink');
  return (
    <>
      <directionalLight
        ref={mainDirectionalLight}
        castShadow
        position={[2.5, 50, 5]}
        intensity={10}
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-camera-far={200}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}
      />
    </>
  );
};

function Environment({ background = false }) {
  const { gl, scene } = useThree();
  const [cubeMap] = useLoader(
    HDRCubeTextureLoader,
    [['px.hdr', 'nx.hdr', 'py.hdr', 'ny.hdr', 'pz.hdr', 'nz.hdr']],
    (loader) => {
      loader.setDataType(THREE.UnsignedByteType);
      loader.setPath('/pisaHDR/');
    }
  );
  useEffect(() => {
    const gen = new THREE.PMREMGenerator(gl);
    gen.compileEquirectangularShader();
    const hdrCubeRenderTarget = gen.fromCubemap(cubeMap);
    cubeMap.dispose();
    gen.dispose();
    if (background) scene.background = hdrCubeRenderTarget.texture;
    scene.environment = hdrCubeRenderTarget.texture;
    return () => (scene.environment = scene.background = null);
  }, [cubeMap]);
  return null;
}

const PhoneLayout = () => {
  const [artist, setArtist] = useState('emilie');
  const [modalArtist, setModalArtist] = useState(false);
  const [modalExpo, setModalExpo] = useState(false);
  const orbit = useRef();

  const artworks = {
    snow: {
      name: 'Snow Yunxe Fu',
      pieces: [
        { file: 'video-snow', title: 'Video 1', type: 'video' },
        { file: 'IMG-5209.JPG', title: 'IMG-5209.JPG', type: 'img' },
        { file: 'IMG-5211.JPG', title: 'IMG-5211.JPG', type: 'img' },
        { file: '', title: '', type: 'img' },
      ],
    },
    emilie: {
      name: 'Emilie Gervais',
      pieces: [
        {
          file: '0BE46D98-1122-4F6E-9BB8-327268C56B58-G8-clip.1.MP4',
          title: '0BE46D98-1122-4F6E-9BB8-327268C56B58-G8-clip.1.MP4',
          type: 'video',
        },
        { file: 'IMG-5209.JPG', title: 'IMG-5209.JPG', type: 'img' },
        { file: 'IMG-5211.JPG', title: 'IMG-5211.JPG', type: 'img' },
        { file: 'IMG-5212.JPG', title: 'IMG-5212.JPG', type: 'img' },
      ],
    },
    domenico: {
      name: 'Domenico Barra',
      pieces: [
        { file: '', title: '', type: 'video' },
        { file: '', title: '', type: 'img' },
        { file: '', title: '', type: 'img' },
        { file: '', title: '', type: 'img' },
      ],
    },
    haydiroley: {
      name: 'Haydiroket',
      pieces: [
        { file: '', title: '', type: 'video' },
        { file: '', title: '', type: 'img' },
        { file: '', title: '', type: 'img' },
        { file: '', title: '', type: 'img' },
      ],
    },
    sandra: {
      name: 'Sandra Araújo',
      pieces: [
        { file: '', title: '', type: 'video' },
        { file: '', title: '', type: 'img' },
        { file: '', title: '', type: 'img' },
        { file: '', title: '', type: 'img' },
      ],
    },
  };

  const [currentArtwork, setCurrentArtwork] = useState(0);

  const handleNext = () => {
    setCurrentArtwork(
      currentArtwork >= artworks[artist].pieces.length - 1 ? 0 : currentArtwork + 1
    );
  };
  const handlePrev = () => {
    setCurrentArtwork(currentArtwork < 1 ? artworks[artist].pieces.length - 1 : currentArtwork - 1);
  };

  const videoURL1 = require('./ARTISTAS_TOOLKIT/SNOW_YUNXUE_FU/windows_Snow.mp4');
  const videoURL2 = require('./ARTISTAS_TOOLKIT/EMILIE_GERVAIS/video-emilie.mp4');
  const videoURL3 = require('./ARTISTAS_TOOLKIT/DOMENICO_BARRA/DomBarra_FucktheSystem.mp4');
  const videoURL4 = require('./ARTISTAS_TOOLKIT/HAYDIROKET/HAYDIROKET-COVIDEO.MP4');
  const videoURL5 = require('./ARTISTAS_TOOLKIT/SANDRA_ARAUJO/01.mp4');

  const videoURL1Ref = useRef();
  const videoURL2Ref = useRef();
  const videoURL3Ref = useRef();
  const videoURL4Ref = useRef();
  const videoURL5Ref = useRef();

  useEffect(() => {
    if(window.innerWidth > 768) {

    
    if (currentArtwork === 0) {
      switch (artist) {
        case "snow":
          videoURL1Ref.current.play();
          break;
        case "emilie":
          videoURL2Ref.current.play();
          break;
        case "domenico":
          videoURL3Ref.current.play();
          break;
        case "haydiroley":
          videoURL4Ref.current.play();
          break;
        case "sandra":
          videoURL5Ref.current.play();
          break;
        default:
          break;
      }
    } else {
      videoURL1Ref.current.pause();
      videoURL2Ref.current.pause();
      videoURL3Ref.current.pause();
      videoURL4Ref.current.pause();
      videoURL5Ref.current.pause();
    }
  }
  }, [artist, currentArtwork]);

  return (
    <>
      <Header expo={() => setModalExpo(true)} />

      <PhoneLayoutStyle>
        <video ref={videoURL1Ref} src={videoURL1} id={'video-snow'}  autoPlay loop controls />
        <video ref={videoURL2Ref} src={videoURL2} id={'video-emilie'} autoPlay  loop controls />
        <video ref={videoURL3Ref} src={videoURL3} id={'video-domenico'} autoPlay  loop controls />
        <video ref={videoURL4Ref} src={videoURL4} id={'video-haydiroley'} autoPlay  loop controls />
        <video ref={videoURL5Ref} src={videoURL5} id={'video-sandra'} autoPlay  loop controls />

        <div className="right-banner">
          <p>TOOLKIT</p>
        </div>
        <div className="navigation">
          <div
            className={`navigation-item ${artist === 'snow' ? 'active' : ''}`}
            onClick={() => {
              setArtist('snow');
              setCurrentArtwork(0);
            }}
          >
            <p>Snow Yunxe Fu</p>
          </div>
          <div
            className={`navigation-item ${artist === 'emilie' ? 'active' : ''}`}
            onClick={() => {
              setArtist('emilie');
              setCurrentArtwork(0);
            }}
          >
            <p>Emilie Gervais</p>
          </div>
          <div
            className={`navigation-item ${artist === 'domenico' ? 'active' : ''}`}
            onClick={() => {
              setArtist('domenico');
              setCurrentArtwork(0);
            }}
          >
            <p>Domenico Barra</p>
          </div>
          <div
            className={`navigation-item ${artist === 'haydiroley' ? 'active' : ''}`}
            onClick={() => {
              setArtist('haydiroley');
              setCurrentArtwork(0);
            }}
          >
            <p>Haydiroket</p>
          </div>
          <div
            className={`navigation-item ${artist === 'sandra' ? 'active' : ''}`}
            onClick={() => {
              setArtist('sandra');
              setCurrentArtwork(0);
            }}
          >
            <p>Sandra Araújo</p>
          </div>
        </div>
        <div className="artwork-navigation-left" onClick={() => handlePrev()}>
          {'<'}
        </div>
        <div className="artwork-navigation-right" onClick={() => handleNext()}>
          {'>'}
        </div>
        <div className="artwork-info">
          <div className="artwork-info-slides">
            {artworks[artist].pieces.map((item, index) => (
              <div
                className={`artwork-info-slides-item ${currentArtwork === index ? 'active' : ''}`}
                onClick={() => setCurrentArtwork(index)}
              ></div>
            ))}
          </div>
          <div className="artwork-info-modal" onClick={() => setModalArtist(true)}>
            {/* <div className="artwork-info__name">
              <p>{artworks[artist].pieces[currentArtwork].title}</p>
            </div> */}
            <div className="artwork-info__description">
              <p>Artwork description</p>
              {/* <p>{artworks[artist].name}</p> */}
            </div>
          </div>
        </div>
        <Canvas
          onCreated={({ gl }) => {
            gl.toneMapping = THREE.ACESFilmicToneMapping;
            gl.outputEncoding = THREE.sRGBEncoding;
          }}
          camera={{
            fov: 50,
            position: [40, 0, 0],
            zoom: 19.5,
            enableZoom: false,
            enablePan: false,
          }}
          shadowMap
        >
          <OrbitControls />
          <ambientLight intensity={0.5} />
          <MainDirectionalLight />
          <Suspense fallback={<LoaderBox />}>
            <IPhoneModel artwork={currentArtwork} artist={artist} />
            <Environment />
          </Suspense>
        </Canvas>
      </PhoneLayoutStyle>
      <ModalArtist
        active={modalArtist}
        onClose={() => setModalArtist(false)}
        artist={artistDescription[artist]}
      />
      <ModalExpo active={modalExpo} onClose={() => setModalExpo(false)} />
    </>
  );
};

export default PhoneLayout;
