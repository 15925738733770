import React from 'react';
import { useHistory } from 'react-router';
import { HeaderStyle } from './header-style';
const logo = require('../../assets/images/logo-black.svg');

const Header = (props) => {
  const history = useHistory();
  return (
    <HeaderStyle>
      <div className="header-logo">
        <a target="_blank" rel="noopener noreferrer" href="http://harddiskmuseum.com/">
          <img src={logo} alt="" />
        </a>
      </div>
      <div className="header-expo" >
        <p onClick={() => (props.expo ? props.expo() : '')}>Toolkit x generate app</p>
        <span>
          for{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://refractionfestival.com/">
            Refraction Festival
          </a>
        </span>
      </div>
    </HeaderStyle>
  );
};

export default Header;
