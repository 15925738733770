import React, { useState } from 'react';

import { ModalArtistStyle } from './modal-artist-style';
import { closeLine } from '../../../assets/icons/icons';

const ModalExpo = ({ onClose, active, artist }) => {
  return (
    <ModalArtistStyle className={`${active ? 'active' : ''}`}>
      <div className="modal-artist-container">
        <div className="modal-artist-container__close" onClick={() => (onClose ? onClose() : '')}>
          <img src={closeLine} alt="" />
        </div>

        <div className="modal-artist-container-content">
          <div className="modal-artist-container-content__section">
            <div className="modal-artist-container-content__title">
              <p>
                TOOLKIT<span>collective exhibition.</span>
              </p>
            </div>
            <div className="modal-artist-container-content__content">
              <p>
                The Harddiskmuseum will celebrate its 5th anniversary in 2020, 5 years since its
                foundation by Solimán López in 2015.
              </p>
              <p>
                Today, digital production and its associated tools are a source of inspiration
                unthinkable a few years ago.
              </p>
              <p>
                After the titanic effort that the contemporary art industry has had trouble assuming
                that the machine is part of the creative process and that a work of art can be
                assisted by electronics, code and digital processes, today we can say without a
                doubt that digital tools are indispensable to talk about our time.
              </p>
              <p>
                The year 2020, in addition to the social revolution brought about by the COVID-19,
                the launch into space of the first private vehicle, the racial revolts in the USA,
                is undoubtedly the year in which our power to analyse reality is increasingly
                collectivised.
              </p>
              <p>
                ToolKit invites the participating artists, members of the Harddiskmuseum, to reflect
                on the novelties of our time, selecting images alluding to it and speaking from the
                visual, of the real problems that touch us today more than ever.
              </p>
              <p>
                In collaboration between HDM and the digital content creation app Generate APP and
                the Refraction Festival promoted by the same team of the application, we invite 5
                artists to generate 3 images and a video using the mentioned application.
              </p>
              <p>
                The result of the exhibition will be shown in our platform space.harddiskmuseum.com
                through an interactive 3D interface where your distributed contents will appear,
                properly labeled and described.
              </p>
            </div>
          </div>
          <div className="modal-artist-container-content__section">
            <div className="modal-artist-container-content__title">
              <p>Exhibition concept:</p>
            </div>
            <div className="modal-artist-container-content__content">
              <p>
                The meaning of the word “tool” has been reinvented since the appearance of the
                so-called "homo habilis". That ancestral human who began to use his nearby
                resources, such as sílex, stones of another typology and even his own bones, as
                instruments to improve his environment, create art and defend himself.
              </p>
              <p>
                Today, these objects are still part of our immediate environment. We believe that
                they are more complex than those of ancient times, but there is no doubt that in a
                few years they will be inert stones.
              </p>
              <p>
                Analyzing the productive possibilities of these multitasking instruments, appears an
                interesting and complex creative experiment in the aims of extracting the capacities
                of the software and the hardware with a purpose; To create art.
              </p>
              <p>
                Is it possible to achieve this goal? Is it an identifiable challenge? Have we
                overcome the introduction of the technological object in contemporary creation?{' '}
              </p>
              <p>
                The mobile device, the cell phone, the phone, the smart phone... is a great
                protagonist of our day to day. Camera, spreadsheet, bank, mailbox, messenger, cook
                and brush, this all-in-one object allows the re-reading of our world and does so in
                an unprecedented way.
              </p>
              <p>
                This task binder kept in a pocket as already predicted by Silicon Valey visionaries
                such as Steve Jobs, or image visionaries such as Vigas Luna is today more than an
                object. It is in itself an artwork, a record, a memory, an icon, an identity and a
                tool for measurement. It is through it that words like "technometries" or the
                measurement of the world through technology emerge.
              </p>
              <p>Solimán López. Harddiskmuseum founder.</p>
              <p>*technometries, term created by Solimán López in 2015.</p>
            </div>
          </div>
        </div>
      </div>
    </ModalArtistStyle>
  );
};

export default ModalExpo;
