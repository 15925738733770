import React from 'react';
import './App.css';
import { BrowserRouter, Switch, Router, Route } from 'react-router-dom';
// import Home from './containers/home/home';
// import Galleries from './containers/gallery-1/galleries';
// import SelectGallerie from './containers/select-gallerie/select-gallery';
// import Gallery2 from './containers/gallery-2/gallery-2';
// import GVHLoaderLayout from './containers/gvh-loader/gvh-loader';
import PhoneLayout from './containers/phone/phone';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Switch>
        {/* <Route path="/phone" component={PhoneLayout}/>
        <Route path="/gvh" component={GVHLoaderLayout}/>
        <Route path="/galleries" component={Galleries}/>
        <Route path="/gallery-2" component={Gallery2}/>
        <Route path="/select-gallery" component={SelectGallerie}/> */}
        <Route path="/" component={PhoneLayout}/>
      </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
