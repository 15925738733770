import React, { useState } from 'react';

import { ModalArtistStyle } from './modal-artist-style';
import { closeLine } from '../../../assets/icons/icons';

const ModalArtist = ({ onClose, active, artist }) => {
  return (
    <ModalArtistStyle className={`${active ? 'active' : ''}`}>
      <div className="modal-artist-container">
        <div className="modal-artist-container__close" onClick={() => (onClose ? onClose() : '')}>
          <img src={closeLine} alt="" />
        </div>

        <div className="modal-artist-container-content">
          <div className="modal-artist-container-content__section">
            <div className="modal-artist-container-content__title">
              <p> {artist.name} <span> {artist.nationality}</span></p>
            </div>
            <div className="modal-artist-container-content__content">
            {artist.bio.map((item)=> (
                <p>{item}</p>
              ))}
            </div>
          </div>
          <div className="modal-artist-container-content__section">
            <div className="modal-artist-container-content__title">
              <p> {artist.title}</p>
            </div>
            <div className="modal-artist-container-content__content">
                <p>{artist.description}</p>
            </div>
            <div className="modal-artist-container-content__contact">
              {/* <div className="modal-artist-container-content__contact__link">
                <a href={artist.link}> {artist.link}</a>
              </div> */}
              <div className="modal-artist-container-content__contact__email">
                <a href={`mailto:${artist.email}`}>{artist.email}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalArtistStyle>
  );
};

export default ModalArtist;
