import styled from 'styled-components';


export const HeaderStyle = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  /* width: 100%; */
  padding: 20px 60px;
  display: flex;
  z-index: 999999;
  align-items: center;
  .header-logo {
    width: 80px;
    mix-blend-mode:difference;
  }

  .header-expo {
    font-size: 14px;
    font-weight: bold;
    padding-left: 12px;
    border-left: 2px solid black;
    p {
    cursor: pointer;
    }
    span {
      color: #777777;
        font-size: 12px;
        font-weight: 400;
      a{
      color: black;

      }
    }
  }

  @media (max-width: 768px) {
    padding: 00px 20px;
  }
`