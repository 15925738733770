export const artistDescription = {
  domenico: {
    name: 'Domenico Barra',
    nationality: 'Italian',
    bio: ['Multimedia artist nurturing visions and imagination through the poetic of failure, noise, glitches, net art, human relations and a mission called #WhitePageGallery. My works have been published on various sites and magazines including The Creators Project, Motherboard, Bullet Magazine, Hyperallergic, Art F City, Monopol, Observer, Artribune, Exibart, Widewalls and Digicult. I took part in numerous curatorial projects and my works have been exhibited at the DAM Gallery in Berlin, at the Media Center in New York, at the Galerie Charlot in Paris, at the Digital Art Center in Taipei and in many other galleries and cultural art events. I directed the organization of the first Glitch Art exhibition in Italy, Tactical Glitches, curated by artists Rosa Menkman and Nick Briz. In 2016 I was a visiting artists at the School of Art Institute of Chicago (SAIC). I teach glitch art and dirty new media at the Rome University of Fine Art (RUFA) and I give lectures and presentations about glitch art and related topics at academies, schools and festivals. I am the creator of the White Page Gallery/s. White Page Gallery/s is a decentralized and distributed art network born in June 2019: it is an online network for artistic sharing composed of artists, curators, academies, festivals and cultural operators who host independently on their website and without the aim of profit art projects made and curated by others.'],
    title: 'Fuck the System',
    description: ['The world is going through radical transformation, the need for change is a collective need, we praise it, we desire it. Many are the communities and networks involved in the many riots, manifestations, activities taking place around the world and online agaisnt racism, against fascists, against pollutions and the extreme toxic waste of our resources, we want to defend our rights, we wnat to defend our future, power to the people. Social media are helping the spreading of information among the communities, we are the witness of our times, we are the narrators of the events. We participate, actively, even from home while we carry on our daily life. Revolution is part of our routine, it is sacrifice but also it is cool, sexy, fun. We make memes to speak and make our protest viral. Fuck the system triptych is based on the famous meme and picket slogan "Sex is good but have you ever fucked the system" and it does it appropriating the slogan and applying it in the context of a private moment, on the bed, presumely after sex, to state that even when we are in our privacy and doing other things we can not stop thinking about revolution, we still take part in the protests, everytime in every occasions and from everywhere we are, simultaniously while we chill, eat, work, fuck, non-stop, always involved and engaged, part of it. We are the revolution!'],
    email: 'rockmyworld@dombarra.art',
  },
  emilie: {
    name: 'Emilie Gervais',
    nationality: 'Canadian',
    bio: ['Practicing in the fields of computer science, art and design, Emilie Gervais’ work focuses on languages, play and network culture while exploring the  relationships between internet, art and its mediation, addressing topics such as identity, aesthetics, functionality, materiality and www archeology.'],
    title: 'Untitled, 2020',
    description: 'Generate: feat apple test flight 2000 (from the mountain)',
    email: 'eee@emiliegervais.com',
  },
  haydiroley: {
    name: 'Haydiroket (Mert Keskin)',
    nationality: 'Turkish',
    bio: ['Working under the pseudonym Haydiroket, Mert Keskin is an Istanbul-based artist and curator. His main mediums are GIF, Video, Collage. He was part of some Demoscene groups in the 90s before having his talents tapped by the likes of MTV, Converse and Tumblr. His work has been exhibited at venues such as The Whitney Museum of American Art (New York), Museum of the Moving Image (New York), Smithsonian’s National Air and Space Museum (Washinton D.C.) etc. He has participated in many exhibitions in China, Canada, the UK, Europe and the US and has worked with numerous companies in the entertainment business. His work has been written about in Complex, The Creator’s Project, Desin Faves, JUXTAPOZ, The Daily Dot, Digital Trends, ArtSlant, Art F City, and Dazed Digital.'],
    title: 'Haydiroket for Toolkit',
    description: 'Selected images filtered by the tool of the digital eye.',
    email: 'haydiroket@yahoo.com',
  },
  sandra: {
    name: 'Sandra Araújo',
    nationality: 'Portugal',
    bio: ['Sandra Araújo is a < non-binary && genderqueer > digital artist that spent endless hours shooting at monsters & strolling through mazes. so, it only felt natural for < them > 2 evolve through an experimental & explorative process of gaming visual culture & popular gif files. also feeds on social media platforms 2 engage < her > animations into the depths of gameplay plots. < they > still plays old school video games.'],
    title: 'per(form)ing < hue > eww',
    description: 'o̶n̶ ̶t̶h̶e̶ fetishisation of technology & the algorithmic body as an influential form / link / node / network of digital reality',
    email: 'sandraiaraujo@gmail.com',
  },
  snow: {
    name: 'Snow Yunxue Fu',
    nationality: 'US',
    bio: ['Snow Yunxue Fu is a US-based Chinese-born New Media Artist, Curator, and Assistant Arts Professor in the Department of Photography and Imaging at New York University Tisch School of the Arts. With a background in painting, Fu remains the youngest artist collected by the National Art Museum of China. She sees her transition into new media as a natural extension of her conceptual research in which she draws parallels between the physical, metaphysical, digital, and multi-dimensional spaces. Fu’s artwork has been shown internationally in solo shows, group exhibitions, screenings, and festivals including the New York Gallery of Chinese Art, New York; Venice Architecture Biennale, Venice, Italy; Pioneer Works, New York; NADA Art Fair, New York; Sediton, Hong Kong; Arebyte Gallery, London, UK; Shenzhen Independent Animation Biennale, Shenzhen, China; Current Museum of Art, New York, Thoma Art House, Santa Fe; Currents Santa Fe New Media Art Festival, New Mexico; The Wrong Biennale, the Internet, and etc. Her work has also been collected by the Currents Museum of Art in New York.',
    'Her work reviews and interviews have been covered in the New York Times, the Boston Globe, Arebyte on Screen, Sedition, the St. Louis Magazine Art Review, Guangzhou Today’s Focus in China, and etc. She participated in residencies such as the Hatch Residency through the Chicago Artist Coalition, Biocultura Residency in Santa Fe NM, Lexington and Concord Artist Residency in Illinois, and Estudio Nómada’s Mas els Igols Residency in Barcelona, Spain. She has given lectures and presented on her work and research in conferences, symposiums, and institutions around the world including International Symposium on Electronic Art (ISEA), SIGGRAPH, SIGGRAPH Asia in Brisbane Australia, the Chinese-American Art Faculty Symposium, Veritas Forum, the School of the Art Institute of Chicago, University of Chicago, and China Academy of Art.'],
    title: 'windows',
    description: 'While being in quarantine, one mainly engages with the outside world through screens with various resolutions. Fu has used the filter on the Generate App that resembles the most as the digital resolution squares on the screen to record from the main window in her Manhattan apartment which outlooks the NYU campus, a corner of the Washington Square Park, and the Empire State building besides other architectures and her house planets. The only direct connection to the outside world that is her window becomes very screen like once again, just as the rest of the digital windows she engages in daily, such as her computer screen and her phone screen.',
    email: 'snowyunxuefu@gmail.com',
  },
};
